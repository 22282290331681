// import { checkAnonyName } from '../units/api.js';

export default {
    /**
     * 1、进行登陆校验  
     * 2、无登陆态进行匿名访问
     * 
     * 登陆验证，组合
     * pid(!0) + s_uir(0)  + type[1]     = 非登陆匿名访问
     * pid(!0) + s_uir(!0) + type[1]     = 已登陆直接进行链接跳转
     * pid(!0) + s_uir(!0) + type[2]     = 已登陆并进行回调方法模式
     * pid(0)  + s_uir(0)                = 跳转至UC登陆页
     * 
     * @params{String} url 跳转链接,如果为空，则尝试走callback回调函数
     * @params{String|Number} pid 产品id/帖子id
     * @params{Function} callback 回调函数
     * @params{Function} warminfo 这里为了使用elementui $message
     * @params{String} model 是否新开窗口, 默认self
     * @params{Object} config 用于callback的回调参数
     * @params{Number} type 默认1，进行匿名,无需登陆验证即可访问。 2不进行匿名接口检测，走回调
     * */
    limitAuthority({ url = '', pid = '', callback = null, warminfo = null, model = 'self', config = {}, type = 1 }) {
        if (pid) {
            if (!window.s_uid) {
                // # 类型一, 进行匿名检测
                if (type == 1) {
                    return this.checkAnonyName(pid, function(res) {
                        if (res && res.data && res.data['anony_visit'] == 1) {
                            if (!url) return callback && callback(config);
                            if (model) setTimeout(() => { window.open(url, model) }, 0);
                            else location.href = url;
                        } else {
                            warminfo && warminfo('请登录后查看社区内容', 1000);
                            setTimeout(() => { 
                                location.href = "//uc.ecer.com/home/login?app_id=200401&app_redirect=" + document.location.href; 
                            }, 1000)
                        }
                    });
                }
            } else {
                // # 类型二，进行回调执行
                if (type == 2) return callback && callback(config);
            }
        }
        if (!window.s_uid) {
            if(url !== '/') warminfo && warminfo('请登录后查看社区内容', 1000);
            setTimeout(() => {
                 location.href = "//uc.ecer.com/home/login?app_id=200401&app_redirect=" + document.location.href; 
            }, 1000)
        } else {
            if (url) {
                if (model) setTimeout(() => { window.open(url, model) }, 0);
                else location.href = url;
            } else {
                callback && callback(config);
            }
        }
    },
    /**
     * 检测匿名
     * */
    checkAnonyName(tid, cb) {
        $.ajax({
            type: 'get',
            url: (document.domain.indexOf('zilaba')>-1 ? '//www.zilaba.com':'//bbs.ecer.com')+'/api-anonyvisit.htm?tid=' + tid,
            success: function(res) {
                console.log(res)
                cb && cb(res)
            }
        })
    }
}