<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'App',
    components: {}
}
</script>
<style>
body {
    background: rgb(250, 252, 253);
}
</style>