// # 兼容ie
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill();
Es6Promise.polyfill();
// # 项目
import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// # 挂在ajax
import axios from 'axios';
// # 带上cookie
axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
// 后端配合调整 header信息 Access-Control-Allow-Credentials:true
Vue.prototype.$axios = axios;
// # 将公用方法挂载
import units from './units/units.js'
Vue.prototype.$units = units;
// # 富文本summernote
import './assets/plugin/bootstrap/css/bootstrap.css';
import './assets/plugin/summernote/css/summernote.css';
import './assets/plugin/summernote/css/codemirror.css';
import './assets/plugin/summernote/css/monokai.css';
import jQuery from 'jquery';
import 'bootstrap';
// import 'summernote';
import './assets/plugin/summernote/js/summernote.js'
import './assets/plugin/summernote/js/summernote-zh-CN.js'
import './assets/plugin/summernote/js/codemirror.js'
import './assets/plugin/summernote/js/codemirror-xml.js'
import './assets/plugin/summernote/js/formatting.js'

// # 图片懒加载
import VueLazyLoad from 'vue-lazyload';

// const loadIMG = require('./assets/logo.png')
const loadIMG = require('./assets/image/default.jpg')
Vue.use(VueLazyLoad, {
    loading: loadIMG,
    error: loadIMG,
    adapter: {
        // # 回调
        loaded: function() {
            // console.log(1)
        },
        loading: function() {},
        error: function() {},
    },
    preload: 1,
    attemp: 1
})

// # 主要为了兄弟节点、数据处理
const bus = new Vue();
Vue.prototype.$bus = bus;


Vue.config.productionTip = false

// # 引入二维码生成器
import qrcode from 'qrcode';
Vue.prototype.$qrcode = qrcode;

// # 动态植入cookie,本地测试用
// import './setCredential.js'
// # 引入 md5
import md5 from 'md5';
Vue.prototype.$md5 = md5;

// # 设置域名全局变量
Vue.prototype.BBSUC_HOST = '//uc.ecer.com'; /* uc.ecer.com */
Vue.prototype.BBSRES_HOST = document.domain.indexOf("zilaba") > -1 ? "//bbsres.zilaba.com" : "//bbsres.ecer.com"; /* bbsres.ecer.com */
// UC_API_HOST:'//uc.ecer.com'
Vue.prototype.BBS_HOST = (document.domain.indexOf("zilaba") > -1 ? "//www.zilaba.com" : "//bbs.ecer.com"); /* bbs.ecer.com */

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')