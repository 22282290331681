import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router);

const routes = [{
        path: '/',
        name: 'home',
        component: () => import('@/views/home.vue')
    },
    {
        path: '/post-new.htm',
        name: 'post-new',
        component: () => import('@/views/publish_post.vue')
    },
    {
        path: '/post-list.htm',
        name: 'post-list',
        component: () => import('@/views/latest_postlist.vue')
    },
    {
        path: '/columnist-earning-list.htm',
        name: 'columnist-earning-list.htm',
        component: ()=> import('@/views/columnist_earning_v1.vue')
    },
    {
        path: '/columnist-earning-history.htm',
        name: 'columnist-earning-history.htm',
        component: ()=> import('@/views/columnist_history.vue')
    }
]

const router = new Router({
    mode: 'history',
    routes
})

export default router